import { useState } from "react";
import Filters from "../../components/Pages/Users/Filters";
import Heading from "../../components/Pages/Users/Heading";
import ClientsList1 from "../../components/Pages/Users/ClientsList1";
import { debounce } from "../../Helpers/utils";
import GlobalSuspense from "../../components/SuspensePages/GlobalSuspense";
import Pagination from "../../components/Pagination";
import { GetAllUsers } from "../../lib/api";


const ClientsList = () => {

    const [pageNumber, setPageNumber] = useState(1)
    const [searchQuery, setSearchQuery] = useState("")
    const [params, setParams] = useState({})

    const paramsData = { page: pageNumber, search_query: searchQuery, ...params };

    const { data, loading } = GetAllUsers(paramsData);
    const users = data?.data?.data ?? [];


    // search filter
    const handleSearchFilter = debounce(txt => {
        setSearchQuery(txt)
    }, 500);


    return (
        <div className="w-full xl:p-8 lg:p-6 sm:p-4 p-2">
            <Heading
                totalRows={data?.data?.total ?? 0}
                paramsData={paramsData}
            />

            <Filters
                params={params}
                setParams={setParams}
                handleSearchFilter={handleSearchFilter}
            />


            {/* Show users list */}
            <div className="w-full overflow-x-auto">
                {loading
                    ? <GlobalSuspense />
                    : <ClientsList1
                        users={users}
                    />
                }
            </div>

            <Pagination
                currentPageNumber={data?.data?.current_page}
                lastPageNumber={data?.data?.last_page}
                onChange={(n) => setPageNumber(n)}
                disabled={loading}
            />
        </div>
    )
}
export default ClientsList;
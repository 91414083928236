import { useState } from "react";
import axiosClient from "../lib/axiosClient"
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import useCookies from "./useCookies";


export const useAuth = () => {


    const navigate = useNavigate()

    const { removeCookie, setCookie, getCookie } = useCookies()


    // check if found cookie "isAuth" or not
    const isAuth = getCookie("isAuth");


    // State to store 'isLoading' value, for all requests in this page
    const [isLoading, setIsLoading] = useState(false)



    // get & set sanctum tokens
    const csrf = () => axiosClient.get('sanctum/csrf-cookie');


    /**
     * Get auth user data
     */
    const authUser = useSWR(isAuth ? "api/auth/admin/user" : null, () =>
        axiosClient.get(isAuth ? "api/auth/admin/user" : null)
            .then(async res => {
                const userData = res.data;

                return userData;
            })
            .catch(async error => {
                if (error.response.data.message === 'Unauthenticated.') {
                    removeCookie("isAuth");
                    setCookie("_uln");

                    navigate('/auth/login')
                }
            })
    );


    /**
     * Login
    */
    const login = async ({ setErrors, setAlert, ...props }) => {
        try {
            setIsLoading(true)

            setErrors({})

            await csrf();

            const { data } = await axiosClient.post('api/auth/admin/login', props);

            if (data?.is_ok === true) {

                // save isAuth cookie
                setCookie("isAuth", true);

                setCookie("_eolla", props.email);

                setCookie("_uln", data?.data?.token ?? "");

                // refresh user data
                authUser.mutate()

                // move to the home page
                navigate('/')
            } else {
                // show danger message
                setAlert(data?.message ?? 'Something was wrong!')
            }

        } catch (error) {
            // return validation errors
            if (error?.response?.data?.type === "validation") {
                setErrors(error?.response?.data?.errors ?? {});
            }

            else if (error?.response?.data?.is_ok === false) {
                setAlert(error?.response?.data?.message ?? 'Something was wrong!')
            }

        } finally {
            setIsLoading(false)
        }

        return {};
    }


    /**
     * Logout
     */
    const logout = async () => {
        try {
            setIsLoading(true)

            await csrf();

            const { data } = await axiosClient.post('api/auth/admin/logout');

            if (data.is_ok === true) {
                removeCookie("isAuth");
                setCookie("_uln");

                navigate('/auth/login')
            }

        } catch (error) {
            if (error.response.data.message === 'Unauthenticated.') {
                removeCookie("isAuth");
                setCookie("_uln");
            }

        } finally {
            setIsLoading(false)
        }
    }


    /**
     * check user role
    */
    const isOwner = () => {
        if (authUser?.data) {
            if (authUser?.data?.role === "owner") {
                return true;
            }
        }
        return false;
    }


    return {
        isAuth,
        authUser,
        csrf,
        login,
        isLoading,
        logout,
        isOwner
    };
}
import { isNull } from "../../../../Helpers/utils"
import NotResultsFound from "../../../NotResultsFound"
import ClientCard1 from "./ClientCard1"


export default function ClientsList1({ clubs }) {

    return isNull(clubs)
        ? <NotResultsFound title={"No results"} desc={"No clients found on this page"} desc2={"try adding new clients"} />
        : (
            <table className="mt-8 w-full">
                <thead>
                    <tr className="[&>*]:px-2 [&>*]:py-2 select-none">
                        <th className="ltr:text-left rtl:text-right lg:textBody-s1 textBody-xs1 whitespace-nowrap">Id</th>
                        <th className="ltr:text-left rtl:text-right lg:textBody-s1 textBody-xs1 whitespace-nowrap">Name</th>
                        <th className="ltr:text-left rtl:text-right lg:textBody-s1 textBody-xs1 whitespace-nowrap">Email</th>
                        <th className="ltr:text-left rtl:text-right lg:textBody-s1 textBody-xs1 whitespace-nowrap max-lg:hidden">Phone</th>
                        <th className="ltr:text-left rtl:text-right lg:textBody-s1 textBody-xs1 whitespace-nowrap max-lg:hidden">Clients</th>
                        <th className="ltr:text-left rtl:text-right lg:textBody-s1 textBody-xs1 whitespace-nowrap max-lg:hidden">Price</th>
                        <th className="ltr:text-left rtl:text-right lg:textBody-s1 textBody-xs1 whitespace-nowrap lg:hidden">C/P</th>
                        <th className="ltr:text-right rtl:text-left lg:textBody-s1 textBody-xs1 whitespace-nowrap">Joined at</th>
                    </tr>
                </thead>
                <tbody>
                    {clubs.length > 0 &&
                        clubs.map((client, index) => {
                            return (
                                <ClientCard1
                                    key={`club_card_1_${client.id}`}
                                    data={client}
                                />
                            )
                        })}
                </tbody>
            </table>
        )
}
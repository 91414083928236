import { Spinner } from "../../icons";

export default function GlobalSuspense({
    heightClass="min-h-96",
}) {
    return (
        <div className={`${heightClass} p-6 flex items-center justify-center`}>
            <Spinner className="w-10 h-10 animate-spin" />
        </div>
    )
}
import { isNull } from "../../../../Helpers/utils"
import NotResultsFound from "../../../NotResultsFound"
import ClientCard1 from "./ClientCard1"


export default function ClientsList1({ clients }) {

    return isNull(clients)
        ? <NotResultsFound title={"No results"} desc={"No clients found on this page"} desc2={"try adding new clients"} />
        : (
            <table className="mt-8 w-full">
                <thead>
                    <tr className="[&>*]:px-2 [&>*]:py-2 select-none">
                        <th className="ltr:text-left rtl:text-right lg:textBody-s1 textBody-xs1 max-lg:hidden">Number</th>
                        <th className="ltr:text-left rtl:text-right lg:textBody-s1 textBody-xs1"><span className="max-lg:hidden">Name</span><span className="lg:hidden">Client</span></th>
                        <th className="ltr:text-left rtl:text-right lg:textBody-s1 textBody-xs1 max-lg:hidden">Contact</th>
                        <th className="ltr:text-left rtl:text-right lg:textBody-s1 textBody-xs1 max-lg:hidden">Sport</th>
                        <th className="ltr:text-left rtl:text-right lg:textBody-s1 textBody-xs1 max-lg:hidden">Price</th>
                        <th className="ltr:text-right rtl:text-left lg:textBody-s1 textBody-xs1">Joined at</th>
                    </tr>
                </thead>
                <tbody>
                    {clients.length > 0 &&
                        clients.map((client, index) => {
                            return (
                                <ClientCard1
                                    key={`client_card_1_${client.id}`}
                                    data={client}
                                />
                            )
                        })}
                </tbody>
            </table>
        )
}
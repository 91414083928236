import { useState } from "react";
import Filters from "../../components/Pages/Affiliaters/Filters";
import Heading from "../../components/Pages/Affiliaters/Heading";
import ClientsList1 from "../../components/Pages/Affiliaters/ClientsList1";
import { debounce } from "../../Helpers/utils";
import GlobalSuspense from "../../components/SuspensePages/GlobalSuspense";
import Pagination from "../../components/Pagination";
import { GetAllAffiliaters, ToggleConfirmationById } from "../../lib/api";
import { useAlerts } from "../../hooks/useAlerts";


const ClientsList = () => {

    const [pageNumber, setPageNumber] = useState(1)
    const [searchQuery, setSearchQuery] = useState("")
    const [params, setParams] = useState({})

    const paramsData = { page: pageNumber, search_query: searchQuery, ...params };

    const { data, loading, refresh } = GetAllAffiliaters(paramsData);
    const clients = data?.data?.data ?? [];

    const { setNotif, renderAlerts } = useAlerts()

    // search filter
    const handleSearchFilter = debounce(txt => {
        setSearchQuery(txt)
    }, 500);


    const [inProgress, setInProgress] = useState("");
    const toggleConfirmation = async (id) => {

        const data = {
            affiliater: id
        };

        setInProgress(id)

        const { is_ok, message, type } = await ToggleConfirmationById(data);

        setInProgress('')

        if (type === "validation") {
            setNotif('Something was wrong!');
        } else {
            setNotif(message ?? (is_ok ? 'Success!' : 'Something was wrong!'), is_ok ? 1 : 0);
        }

        if (is_ok) {
            refresh()
        }
    }

    return (
        <div className="w-full xl:p-8 lg:p-6 sm:p-4 p-2">
            {renderAlerts()}

            <Heading
                totalRows={data?.data?.total ?? 0}
                paramsData={paramsData}
            />

            <Filters
                params={params}
                setParams={setParams}
                handleSearchFilter={handleSearchFilter}
            />


            {/* Show clients list */}
            <div className="w-full overflow-x-auto">
                {loading
                    ? <GlobalSuspense />
                    : <ClientsList1
                        clients={clients}
                        toggleConfirmation={toggleConfirmation}
                        inProgress={inProgress}
                    />
                }
            </div>

            <Pagination
                currentPageNumber={data?.data?.current_page}
                lastPageNumber={data?.data?.last_page}
                onChange={(n) => setPageNumber(n)}
                disabled={loading}
            />
        </div>
    )
}
export default ClientsList;
import dayjs from "../../../../lib/dayjs";


export default function ClientCard1({ data = {} }) {

    const { id, name, email, phone, total_clients, total_price, created_at } = data;

    return (
        <tr className="[&>*]:px-2 [&>*]:py-5 border-t border-primary/10 cursor-context-menu select-none hover:bg-primary2">
            <td>
                <span className="text-secondaryTextColor lg:textBody-s3 textBody-xs3">{`#${id}`}</span>
            </td>
            <td>
                <div className="flex flex-col gap-1 lg:textBody-s2 textBody-xs2">
                    <span className="line-clamp-1">{name ?? 'null'}</span>
                </div>
            </td>
            <td>
                <div className="flex flex-col gap-1 lg:textBody-s3 textBody-xs3">
                    <span className="text-primary hover:text-primary/90 line-clamp-1">{email ?? 'null'}</span>
                    <span className="text-primary hover:text-primary/90 line-clamp-1 lg:hidden">{phone ?? 'null'}</span>
                </div>
            </td>
            <td className="max-lg:hidden">
                <div className="flex flex-col gap-1 lg:textBody-s3 textBody-xs3">
                    <span className="text-primary hover:text-primary/90 line-clamp-1">{phone ?? 'null'}</span>
                </div>
            </td>
            <td className="max-lg:hidden">
                <div className="flex flex-col gap-1 lg:textBody-s3 textBody-xs3">
                    <span className="text-primary hover:text-primary/90 line-clamp-1">{total_clients ?? 0}</span>
                </div>
            </td>
            <td className="max-lg:hidden">
                <div className="flex flex-col gap-1 lg:textBody-s3 textBody-xs3">
                    <span className="text-primary hover:text-primary/90 line-clamp-1">{total_price ?? 0}Dh</span>
                </div>
            </td>
            <td className="lg:hidden">
                <div className="flex flex-col gap-1 lg:textBody-s3 textBody-xs3">
                    <span className="text-primary hover:text-primary/90 line-clamp-1">{`${total_clients ?? 0}`}</span>
                    <span className="text-primary hover:text-primary/90 line-clamp-1">{`${total_price ?? 0}Dh`}</span>
                </div>
            </td>
            <td className="ltr:text-right rtl:text-left">
                <span className="lg:textBody-xs3 textBody-2xs3">{dayjs(created_at).format("D MMMM, YYYY")} <span className="max-lg:hidden">({dayjs(created_at).fromNow()})</span></span>
            </td>
        </tr>
    )
}
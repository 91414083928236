import CountUp from 'react-countup';
import GlobalSuspense from '../../components/SuspensePages/GlobalSuspense';
import { GetDashboardStatistics } from "../../lib/api";
import { Link } from 'react-router-dom';


const Dashboard = () => {


    const { data, loading } = GetDashboardStatistics();

    const { clients, clubs, members, tickets, users, affiliaters, total_price } = data?.data ?? {};


    return loading ? <GlobalSuspense /> : (
        <div className="xl:p-8 lg:p-6 sm:p-4 p-2 select-none">
            <div className="lg:grid-cols-4 grid-cols-2 lg:gap-4 gap-2 grid h-full">
                <Link to="/clubs">
                    <div className="group hover:bg-primary2 border border-primaryBorder/50 rounded-lg p-4 flex flex-col gap-4 transition-colors duration-200 ease-in cursor-pointer">
                        <h3 className='textBody-ml1'>Clubs</h3>
                        <span className="text-[24px] leading-[17px] text-secondaryTextColor group-hover:text-primaryTextColor transition-colors duration-200 ease-in">
                            <CountUp delay={0} duration={1} end={clubs} />
                        </span>
                    </div>
                </Link>

                <Link to="/clients">
                    <div className="group hover:bg-primary2 border border-primaryBorder/50 rounded-lg p-4 flex flex-col gap-4 transition-colors duration-200 ease-in cursor-pointer">
                        <h3 className='textBody-ml1'>Clients</h3>
                        <span className="text-[24px] leading-[17px] text-secondaryTextColor group-hover:text-primaryTextColor transition-colors duration-200 ease-in">
                            <CountUp delay={0} duration={1} end={clients} />
                        </span>
                    </div>
                </Link>

                <div className="group hover:bg-primary2 border border-primaryBorder/50 rounded-lg p-4 flex flex-col gap-4 transition-colors duration-200 ease-in cursor-pointer">
                    <h3 className='textBody-ml1'>Members</h3>
                    <span className="text-[24px] leading-[17px] text-secondaryTextColor group-hover:text-primaryTextColor transition-colors duration-200 ease-in whitespace-nowrap">
                        <CountUp delay={0} duration={1} end={members} />
                    </span>
                </div>

                <div className="group hover:bg-primary2 border border-primaryBorder/50 rounded-lg p-4 flex flex-col gap-4 transition-colors duration-200 ease-in cursor-pointer">
                    <h3 className='textBody-ml1'>Total paid price</h3>
                    <span className="text-[24px] leading-[17px] text-green-600 transition-colors duration-200 ease-in whitespace-nowrap">
                        <CountUp delay={0} duration={1} end={total_price} /> Dh
                    </span>
                </div>

                <Link to="/tickets">
                    <div className="group hover:bg-primary2 border border-primaryBorder/50 rounded-lg p-4 flex flex-col gap-4 transition-colors duration-200 ease-in cursor-pointer">
                        <h3 className='textBody-ml1'>Tickets</h3>
                        <span className="text-[24px] leading-[17px] text-secondaryTextColor group-hover:text-primaryTextColor transition-colors duration-200 ease-in whitespace-nowrap">
                            <CountUp delay={0} duration={1} end={tickets} />
                        </span>
                    </div>
                </Link>
                
                <Link to="/affiliaters">
                    <div className="group hover:bg-primary2 border border-primaryBorder/50 rounded-lg p-4 flex flex-col gap-4 transition-colors duration-200 ease-in cursor-pointer">
                        <h3 className='textBody-ml1'>Affiliaters</h3>
                        <span className="text-[24px] leading-[17px] text-secondaryTextColor group-hover:text-primaryTextColor transition-colors duration-200 ease-in whitespace-nowrap">
                            <CountUp delay={0} duration={1} end={affiliaters} />
                        </span>
                    </div>
                </Link>
                
                <Link to="/users">
                    <div className="group hover:bg-primary2 border border-primaryBorder/50 rounded-lg p-4 flex flex-col gap-4 transition-colors duration-200 ease-in cursor-pointer">
                        <h3 className='textBody-ml1'>All users</h3>
                        <span className="text-[24px] leading-[17px] text-secondaryTextColor group-hover:text-primaryTextColor transition-colors duration-200 ease-in whitespace-nowrap">
                            <CountUp delay={0} duration={1} end={users} />
                        </span>
                    </div>
                </Link>
            </div>
        </div>
    )
}
export default Dashboard;

export default function Heading({ totalRows, toggleCreateModel }) {
    return (
        <div className="w-full">
            <div className="w-full flex lg:justify-between lg:items-center max-lg:flex-col-reverse">
                {/* Text */}
                <div className="flex flex-col gap-1">
                    <h2 className="flex items-center textHeader-l">
                        Countries list
                        <span className="border border-primaryBorder bg-white text-secondaryTextColor py-1 px-3 flex w-fit rounded-3xl select-none mx-2 textBody-xs3">
                            {totalRows}
                        </span>
                    </h2>
                    <p className="textBody-s3">View, add, edit and delete your countries's details.</p>
                </div>

                {/* Buttons */}
                <div className="flex mb-2">
                    <button onClick={toggleCreateModel} className="bg-primary hover:bg-primary/80 border-[1.5px] border-primary hover:border-primary/80 text-white rounded-lg lg:py-3 py-2 lg:px-6 px-2 transition-all duration-100 ease-in active:scale-[.97] flex items-center gap-2 textBody-m1">
                        <span className="">Add new</span>
                    </button>
                </div>
            </div>
        </div>
    )
}
import { useState } from "react";
import { GetAllTickets } from "../../lib/api";
import Heading from "../../components/Pages/Tickets/Heading";
import ClientsList1 from "../../components/Pages/Tickets/ClientsList1";
import Pagination from "../../components/Pagination";
import GlobalSuspense from "../../components/SuspensePages/GlobalSuspense";
import Filters from "../../components/Pages/Tickets/Filters";

export default function Tickets() {

    const [pageNumber, setPageNumber] = useState(1)
    const [params, setParams] = useState({})

    const paramsData = { page: pageNumber, ...params };

    const { data, loading } = GetAllTickets(paramsData);
    const tickets = data?.data?.data ?? [];


    return (
        <div className="w-full xl:p-8 lg:p-6 sm:p-4 p-2">
            <Heading
                totalRows={data?.data?.total ?? 0}
            />

            <Filters
                params={params}
                setParams={setParams}
            />

            {/* Show tickets list */}
            <div className="w-full overflow-x-auto">
                {loading
                    ? <GlobalSuspense />
                    : <ClientsList1
                        tickets={tickets}
                    />
                }
            </div>

            <Pagination
                currentPageNumber={data?.data?.current_page}
                lastPageNumber={data?.data?.last_page}
                onChange={(n) => setPageNumber(n)}
                disabled={loading}
            />
        </div>
    )
}
import axios from 'axios'
import { getCookie } from './cookies';


const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    withCredentials: true,
    withXSRFToken: true,
})


// Add a request interceptor to set the Lang header
axiosClient.interceptors.request.use((config) => {
    const selectedLang = getCookie('i18next') || 'en';
    config.headers['FrontLang'] = selectedLang;
    return config;
}, (error) => {
    return Promise.reject(error);
});


axiosClient.interceptors.request.use((config) => {
    const token = getCookie('_uln') || '';
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});


export default axiosClient;

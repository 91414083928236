import { useNavigate } from "react-router-dom";
import { renderImage } from "../../../../Helpers/utils";


export default function ClientCard1({ data = {}, select }) {

    const { id, en_name, fr_name, ar_name, country_code, phone_code, total_cities, country_flag, is_visible } = data;


    const navigate = useNavigate()

    const edit = () => {
        select(id)
    }

    const fetchCities = () => {
        navigate(`/cities/${id}`)
    }

    return (
        <tr
            className="[&>*]:px-2 [&>*]:py-5 border-t border-primary/10 select-none hover:bg-primary2 cursor-pointer">
            <td>
                <span className="text-secondaryTextColor lg:textBody-s3 textBody-xs3">{`#${id}`}</span>
            </td>
            <td onClick={fetchCities} className="hover:underline">
                <span className="lg:textBody-s2 textBody-xs2">{en_name}</span>
            </td>
            <td onClick={fetchCities} className="hover:underline">
                <span className="lg:textBody-s2 textBody-xs2">{fr_name}</span>
            </td>
            <td onClick={fetchCities} className="hover:underline">
                <span className="lg:textBody-s2 textBody-xs2">{ar_name}</span>
            </td>
            <td onClick={edit} className="">
                <span className="text-primary hover:text-primary/90 line-clamp-1">{country_code ?? 'null'}</span>
            </td>
            <td onClick={edit} className="">
                <span className="text-primary hover:text-primary/90 line-clamp-1">{phone_code ?? 'null'}</span>
            </td>
            <td onClick={edit} className="">
                <span className="lg:textBody-s3 textBody-xs3">
                    <img src={renderImage(country_flag)} alt="flag" className="h-4" />
                </span>
            </td>
            <td onClick={edit} className="">
                <span className={`lg:textBody-xs2 textBody-2xs2 ${is_visible ? 'text-green-600' : 'text-red-600'}`}>{is_visible ? 'Yes' : 'No'}</span>
            </td>
            <td onClick={edit} className="ltr:text-right rtl:text-left">
                <span className="lg:textBody-s3 textBody-xs3">{total_cities ?? ''}</span>
            </td>
        </tr>
    )
}
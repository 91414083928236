


export const isObjNull = (obj) => {
    if (obj === undefined) {
        return true;
    }
    return Object.entries(obj).length === 0 && obj.constructor === Object;
};


// chacke if string, array, object is empty or not
export const isNull = (value) => {
    if (!value) {
        return true;
    }
    if (value === "null") {
        return true;
    } else if (Array.isArray(value) || typeof value === "string") {
        return !value.length;
    } else if (typeof value === "object") {
        return isObjNull(value);
    } else if (value !== null) {
        return false;
    }
    return true;
};


// return value as number
export const n = (number) => {
    return Number(number)
}


// remove spaces from any string
export const trim = (string) => {
    return string.trim()
}

// return user's firstname and lastname
export const getFullName = (arr) => {
    return `${arr?.firstname ?? 'firstname'} ${arr?.lastname ?? 'lastname'}`
}

// render console.log()
export const see = (...props) => {
    console.log(...props)
}

// render images
export const renderImage = (path) => {
    if (isNull(path)) return null;
    const backEndUrl = process.env.REACT_APP_API_URL;
    const imageUrl = `${backEndUrl.slice(0, -1) + path ?? ""}`;
    return imageUrl;
}

// generate a random value for 'membership_number'
export const getRandomMembershipNumber = (length = 5) => {
    const characters = '123456789';
    const charactersLength = characters.length;
    let randomStr = '';
    for (let i = 0; i < length; i++) {
        randomStr += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return n(randomStr);
}


/**
 * Debounce event handlers
 */
export function debounce(a, b, c) {
    let d, e;
    return function () {
        const f = this,
            g = arguments;

        const h = () => {
            d = null;
            if (!c) e = a.apply(f, g);
        };

        clearTimeout(d);
        d = setTimeout(h, b);
        if (c && !d) e = a.apply(f, g);

        return e;
    };
}


/**
 * validate Moroccan phone numbers
 * @param {*} value
 * @returns `boolean`
 */
export const isMoroccanNumber = (value) => {
    const moroccanRegex = /^\+212\s(0\d{9}|\d{9})$/;
    return moroccanRegex.test(value);
};


/**
 * validate moroccan phone numbers
 * @param {*} val
 * @returns `string`
 */
export const letPhoneNumberStartsWithZero = (val) => {
    if (isNull(val)) return "";

    const phone = val?.split(' ');
    const number = phone?.[1];

    let validatedNumber = val;

    if (!number?.startsWith('0')) {
        validatedNumber = `${phone[0]} 0${phone[1]}`
    }
    
    return validatedNumber;
}
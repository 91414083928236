import { useState } from "react";
import { isNull } from "../../../../Helpers/utils";
import FormModal from "../../../../components/Shared/FormModal"
import { CreateNewCountry } from "../../../../lib/api";



export default function Create({ closeModel, refresh, setNotif }) {

    const [en_name, setEn_Name] = useState("")
    const [fr_name, setFr_Name] = useState("")
    const [ar_name, setAr_Name] = useState("")
    const [countryCOde, setCountryCOde] = useState("")
    const [phoneCode, setPhoneCode] = useState("")
    const [status, setStatus] = useState("0")
    const [flag, setFlag] = useState("")

    const [errors, setErrors] = useState({})
    const [inProgress, setInProgress] = useState(false)


    // handle change user avatar
    const handleSelectFlag = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setFlag(reader.result); // Base64 string
            };
            reader.readAsDataURL(file);
        } else {
            setFlag('')
        }
    }


    // handle click 'saveNew'
    const saveNew = async () => {

        const data = {
            en_name,
            fr_name,
            ar_name,
            country_code: countryCOde,
            phone_code: phoneCode,
            flag,
            status,
        };

        setErrors({})
        setInProgress(true)

        const { is_ok, message, type, errors } = await CreateNewCountry(data);

        setInProgress(false)

        if (type === "validation") {
            setErrors(errors ?? {});
        } else {
            setNotif(message ?? (is_ok ? 'Success!' : 'Something was wrong!'), is_ok ? 1 : 0);
        }

        if (is_ok) {
            refresh()
            closeModel();
        }
    }

    return (
        <FormModal
            close={closeModel}
            title={"Add new country"}
            save={saveNew}
            isDisabled={inProgress}
            isLoading={inProgress}
        >
            <div className="w-full grid grid-cols-1 gap-6">
                <div className="w-full grid grid-cols-3 lg:gap-4 gap-2">
                    <label className="flex flex-col gap-2">
                        <span className="textBody-xs2">En name</span>
                        <div className={`flex justify-between transition-colors duration-300 rounded-lg overflow-hidden ${!isNull(errors.en_name) ? 'border border-red-600 outline-none' : 'border border-primaryBorder/60 hover:border-primaryBorder/90'}`}>
                            <input
                                type="text"
                                className={`w-full p-3 outline-none`}
                                onChange={(e) => setEn_Name(e.target.value)}
                                value={en_name}
                            />
                        </div>
                        {!isNull(errors.en_name) && <p className="textBody-s2 text-red-600">{errors.en_name}</p>}
                    </label>

                    <label className="flex flex-col gap-2">
                        <span className="textBody-xs2">Fr name</span>
                        <div className={`flex justify-between transition-colors duration-300 rounded-lg overflow-hidden ${!isNull(errors.fr_name) ? 'border border-red-600 outline-none' : 'border border-primaryBorder/60 hover:border-primaryBorder/90'}`}>
                            <input
                                type="text"
                                className={`w-full p-3 outline-none`}
                                onChange={(e) => setFr_Name(e.target.value)}
                                value={fr_name}
                            />
                        </div>
                        {!isNull(errors.fr_name) && <p className="textBody-s2 text-red-600">{errors.fr_name}</p>}
                    </label>

                    <label className="flex flex-col gap-2">
                        <span className="textBody-xs2">Ar name</span>
                        <div className={`flex justify-between transition-colors duration-300 rounded-lg overflow-hidden ${!isNull(errors.ar_name) ? 'border border-red-600 outline-none' : 'border border-primaryBorder/60 hover:border-primaryBorder/90'}`}>
                            <input
                                type="text"
                                className={`w-full p-3 outline-none`}
                                onChange={(e) => setAr_Name(e.target.value)}
                                value={ar_name}
                            />
                        </div>
                        {!isNull(errors.ar_name) && <p className="textBody-s2 text-red-600">{errors.ar_name}</p>}
                    </label>
                </div>

                <div className="w-full grid grid-cols-3 lg:gap-4 gap-2">
                    <label className="flex flex-col gap-2">
                        <span className="textBody-xs2">Country code</span>
                        <div className={`flex justify-between transition-colors duration-300 rounded-lg overflow-hidden ${!isNull(errors.countryCOde) ? 'border border-red-600 outline-none' : 'border border-primaryBorder/60 hover:border-primaryBorder/90'}`}>
                            <input
                                type="text"
                                className={`w-full p-3 outline-none`}
                                onChange={(e) => setCountryCOde(e.target.value)}
                                value={countryCOde}
                            />
                        </div>
                        {!isNull(errors.countryCOde) && <p className="textBody-s2 text-red-600">{errors.countryCOde}</p>}
                    </label>

                    <label className="flex flex-col gap-2">
                        <span className="textBody-xs2">Phone code</span>
                        <div className={`flex justify-between transition-colors duration-300 rounded-lg overflow-hidden ${!isNull(errors.phoneCode) ? 'border border-red-600 outline-none' : 'border border-primaryBorder/60 hover:border-primaryBorder/90'}`}>
                            <input
                                type="text"
                                className={`w-full p-3 outline-none`}
                                onChange={(e) => setPhoneCode(e.target.value)}
                                value={phoneCode}
                            />
                        </div>
                        {!isNull(errors.phoneCode) && <p className="textBody-s2 text-red-600">{errors.phoneCode}</p>}
                    </label>

                    <label className="flex flex-col gap-2">
                        <span className="textBody-xs2">Status</span>
                        <div className={`flex justify-between transition-colors duration-300 rounded-lg overflow-hidden ${!isNull(errors.status) ? 'border border-red-600 outline-none' : 'border border-primaryBorder/60 hover:border-primaryBorder/90'}`}>
                            <select
                                value={status}
                                onChange={(e) => setStatus(e.target.value)}
                                className={`w-full p-3 outline-none appearance-none`}
                            >
                                <option value="1">active</option>
                                <option value="0">inactive</option>
                            </select>
                        </div>
                        {!isNull(errors.status) && <p className="textBody-s2 text-red-600">{errors.status}</p>}
                    </label>
                </div>

                <div className="flex items-center">
                    <label className="flex flex-col gap-2">
                        <span className="textBody-xs2">Flag</span>
                        <div className={`flex justify-between transition-colors duration-300 overflow-hidden ${flag ? 'h-auto w-14' : 'h-14 w-14 rounded-lg'} cursor-pointer hover:opacity-90 ${!isNull(errors.flag) ? 'border border-red-600 outline-none' : 'border border-primaryBorder/60 hover:border-primaryBorder/90'}`}>
                            <input
                                type="file"
                                className={`w-full p-3 outline-none appearance-none`}
                                onChange={handleSelectFlag}
                                accept="image/png, image/jpg, image/jpeg"
                                hidden
                            />
                            {flag && <img src={flag} alt="flag" className="w-full h-full" />}
                        </div>
                        {!isNull(errors.flag) && <p className="textBody-s2 text-red-600">{errors.flag}</p>}
                    </label>
                </div>
            </div>
        </FormModal>
    )
}
import { Link, useLocation } from "react-router-dom";



export default function SideBar({ isOpen, isMobile, close }) {

    // Get path name
    const location = useLocation();
    const { pathname } = location;

    const asideLinks = [
        {
            label: "Dashboard",
            path: '/',
        }, {
            label: "Users",
            path: '/users',
        }, {
            label: "Clubs",
            path: '/clubs',
        }, {
            label: "Clients",
            path: '/clients',
        }, {
            label: "Tickets",
            path: '/tickets',
        }, {
            label: "Activities",
            path: '/activities',
        }, {
            label: "Countries",
            path: '/countries',
        }, {
            label: "Currencies",
            path: '/currencies',
        }, {
            label: "Affiliaters",
            path: '/affiliaters',
        },
    ];

    return (
        <aside
            className={`
                ${isOpen
                    ? 'max-lg:fixed sticky lg:top-[70px] top-[60px] lg:h-[calc(100vh_-_70px)] h-[calc(100vh_-_60px)] w-[200px] z-[1001]'
                    : 'hidden'
                }
                overflow-y-auto overflow-x-hidden
                flex shrink-0 flex-col justify-between items-center bg-tertiaryBg p-3 scrollBar1
                `}>
            <div className="w-full h-full text-white flex flex-col textBody-s3">
                {asideLinks.map((link, index) => {
                    return (
                        <Link
                            key={`link_${index}`}
                            to={`${link.path ?? '/'}`}
                            onClick={() => {
                                if (isMobile) {
                                    close()
                                }
                            }}
                        >
                            <div
                                className={`flex items-center gap-2 mb-3 rounded-lg text-white transition-colors duration-200 px-3 py-2
                                ${pathname === link.path ? 'bg-primary cursor-pointer' : 'hover:bg-primary/5'}
                            `}>
                                <span className="whitespace-nowrap">{link.label}</span>
                            </div>
                        </Link>
                    )
                })}
            </div>
        </aside>
    )
}
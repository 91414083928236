import { isNull } from "../../../../Helpers/utils"
import NotResultsFound from "../../../NotResultsFound"
import ClientCard1 from "./ClientCard1"


export default function ClientsList1({ clients, toggleConfirmation, inProgress }) {

    return isNull(clients)
        ? <NotResultsFound title={"No results"} desc={"No clients found on this page"} desc2={"try adding new clients"} />
        : (
            <table className="mt-8 w-full">
                <thead>
                    <tr className="[&>*]:px-2 [&>*]:py-2 select-none">
                        <th className="text-left lg:textBody-s1 textBody-xs1">ID</th>
                        <th className="text-left lg:textBody-s1 textBody-xs1">Client</th>
                        <th className="text-left lg:textBody-s1 textBody-xs1">Contact</th>
                        <th className="text-left lg:textBody-s1 textBody-xs1">isConfirmed</th>
                        <th className="text-left lg:textBody-s1 textBody-xs1">isDeleted</th>
                        <th className="text-left lg:textBody-s1 textBody-xs1">Joined at</th>
                        <th className="text-right lg:textBody-s1 textBody-xs1">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {clients.length > 0 &&
                        clients.map((client, index) => {
                            return (
                                <ClientCard1
                                    key={`client_card_1_${client.id}`}
                                    data={client}
                                    toggleConfirmation={toggleConfirmation}
                                    inProgress={inProgress}
                                    disableButtons={!isNull(inProgress)}
                                />
                            )
                        })}
                </tbody>
            </table>
        )
}
import { renderImage } from "../../../../Helpers/utils";
import dayjs from "../../../../lib/dayjs";
import Avatar from "../../../Shared/Avatar";


export default function ClientCard1({ data = {} }) {

    const { id, firstname, lastname, email, phone, avatar, gender, role, created_at } = data;

    return (
        <tr className="[&>*]:px-2 [&>*]:py-5 border-t border-primary/10 cursor-context-menu select-none hover:bg-primary2">
            <td className="max-lg:hidden">
                <span className="text-secondaryTextColor lg:textBody-s3 textBody-xs3">{`#${id}`}</span>
            </td>
            <td>
                <div className="flex lg:items-center gap-2">
                    <Avatar
                        src={renderImage(avatar)}
                        alt={`${firstname} ${lastname}`}
                    />
                    <div className="flex flex-col">
                        <h3 className="lg:textBody-m3 textBody-s2">{`${firstname} ${lastname}`}</h3>
                        <span className="text-primary hover:text-primary/90 textBody-xs3 lg:hidden">{email ?? 'null'}</span>
                        <span className="text-primary hover:text-primary/90 textBody-xs3 lg:hidden">{phone ?? 'null'}</span>
                    </div>
                </div>
            </td>
            <td className="max-lg:hidden">
                <div className="flex flex-col gap-1 lg:textBody-s3 textBody-xs3">
                    <span className="text-primary hover:text-primary/90 line-clamp-1">{email ?? 'null'}</span>
                    <span className="text-primary hover:text-primary/90 line-clamp-1">{phone ?? 'null'}</span>
                </div>
            </td>
            <td className="ltr:text-right rtl:text-left">
                <span className="lg:textBody-xs3 textBody-2xs3">{gender ?? "#"}</span>
            </td>
            <td className="ltr:text-right rtl:text-left">
                <span className="lg:textBody-xs3 textBody-2xs3">{role ?? "#"}</span>
            </td>
            <td className="ltr:text-right rtl:text-left">
                <span className="lg:textBody-xs3 textBody-2xs3">{dayjs(created_at).format("D MMMM, YYYY")} <span className="max-lg:hidden">({dayjs(created_at).fromNow()})</span></span>
            </td>
        </tr>
    )
}
import { useState } from "react";
import Filters from "../../components/Pages/Cities/Filters";
import Heading from "../../components/Pages/Cities/Heading";
import ClientsList1 from "../../components/Pages/Cities/ClientsList1";
import { debounce, isNull, n } from "../../Helpers/utils";
import GlobalSuspense from "../../components/SuspensePages/GlobalSuspense";
import Pagination from "../../components/Pagination";
import { GetAllCitiesByCountryId } from "../../lib/api";
import Create from "../../components/Pages/Cities/Create";
import Edit from "../../components/Pages/Cities/Edit";
import { useAlerts } from "../../hooks/useAlerts";
import { useParams } from "react-router-dom";


const Cities = () => {

    const { id } = useParams();

    const { setNotif, renderAlerts } = useAlerts()

    const [pageNumber, setPageNumber] = useState(1)
    const [searchQuery, setSearchQuery] = useState("")
    const [params, setParams] = useState({})

    const paramsData = {
        page: pageNumber,
        search_query: searchQuery,
        country_id: id,
        ...params
    };

    const { data, loading, refresh } = GetAllCitiesByCountryId(isNull(id) ? {} : paramsData);
    const country = data?.data?.country ?? [];
    const cities = data?.data?.cities?.data ?? [];


    // search filter
    const handleSearchFilter = debounce(txt => {
        setSearchQuery(txt)
    }, 500);


    // state to store 'create' modal status
    const [showCreateModel, setShowCreateModel] = useState(false)
    const toggleCreateModel = () => setShowCreateModel(!showCreateModel);


    // State to store id of selected country
    const [selectedCityId, setSelectedCityId] = useState("")


    // Get Obj of selected country
    const selectedCityData = !isNull(selectedCityId) ? cities?.find(c => c.id === n(selectedCityId)) : {};


    return (
        <div className="w-full xl:p-8 lg:p-6 sm:p-4 p-2">
            {renderAlerts()}

            <Heading
                country={country}
                totalRows={data?.data?.cities?.total ?? 0}
                paramsData={paramsData}
                toggleCreateModel={toggleCreateModel}
            />

            <Filters
                params={params}
                setParams={setParams}
                handleSearchFilter={handleSearchFilter}
            />


            {/* Show cities list */}
            <div className="w-full overflow-x-auto">
                {loading
                    ? <GlobalSuspense />
                    : <ClientsList1
                        cities={cities}
                        select={setSelectedCityId}
                    />
                }
            </div>


            {/* Create */}
            {showCreateModel && (
                <Create
                    country={country}
                    closeModel={toggleCreateModel}
                    refresh={refresh}
                    setNotif={setNotif}
                />
            )}


            {/* Edit */}
            {!isNull(selectedCityData) && (
                <Edit
                    country={country}
                    city={selectedCityData}
                    closeModel={() => setSelectedCityId("")}
                    refresh={refresh}
                    setNotif={setNotif}
                />
            )}

            <Pagination
                currentPageNumber={data?.data?.cities?.current_page}
                lastPageNumber={data?.data?.cities?.last_page}
                onChange={(n) => setPageNumber(n)}
                disabled={loading}
            />
        </div>
    )
}
export default Cities;
import { useState } from "react";
import { isNull } from "../Helpers/utils";
import ModalLayout from "../layouts/ModalLayout";
import { Hide1 } from "../icons";


export const useAlerts = () => {

    // state to store messages
    const [message, setMessage] = useState({})
    const closeMessage = () => setMessage({});

    
    // state to store notifications
    const [notification, setNotification] = useState({})
    const closeNotification = () => setNotification({});


    // check new message to show
    const setAlert = (message = 'unknown error', type = 0) => {
        setMessage({ text: message, type: type })
    }


    // set notifications
    const setNotif = (notif = 'unknown message', type = 0) => {
        setNotification({ text: notif, type: type })

        // Hide notification after 3 seconds
        setTimeout(() => {
            setNotification({});
        }, 3000);
    }


    // show or view message
    const alertContent = (txt, type) => {

        const isSuccess = type === 1;

        return (
            <ModalLayout
                wallBackground={"bg-black/10"}
                className={"w-full max-w-96 fixed -translate-x-2/4 left-2/4 top-8 z-[1020] animation-01 alerts-animation-1"}
                close={closeMessage}
            >
                <div className={`${isSuccess ? 'bg-green-500' : 'bg-red-500'} text-white p-4 w-full rounded-lg shadow-lg flex items-center gap-1 justify-between`}>
                    <div className="w-full select-none textBody-s3">
                        <p>{txt}</p>
                    </div>
                    <button onClick={closeMessage} className="hover:scale-105">
                        <Hide1 className="w-5 h-5" />
                    </button>
                </div>
            </ModalLayout>
        );
    }


    // notification
    const notificationContent = (txt, type) => {

        const isSuccess = type === 1;

        return (
            <div className={"w-full max-w-96 fixed -translate-x-2/4 left-2/4 lg:bottom-8 max-lg:top-10 z-[1020] animation-01 alerts-animation-2"}>
                <div className={`${isSuccess ? 'bg-green-500' : 'bg-red-500'} text-white p-4 w-full rounded-lg shadow-lg flex items-center gap-1 justify-between`}>
                    <div className="w-full select-none textBody-s3">
                        <p>{txt}</p>
                    </div>
                    <button onClick={closeNotification} className="hover:scale-105">
                        <Hide1 className="w-5 h-5" />
                    </button>
                </div>
            </div>
        )
    }


    // render alerts
    const renderAlerts = () => {

        if (!isNull(message)) {
            return alertContent(message.text, message.type)
        }

        else if (!isNull(notification)) {
            return notificationContent(notification.text, notification.type)
        }

        else {
            return null;
        }
    }


    return {
        setAlert,
        setNotif,
        renderAlerts,
    };
}
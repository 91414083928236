import dayjs from "../../../../lib/dayjs";


export default function ClientCard1({ data = {} }) {

    const { id, fullname, email, message, created_at } = data;

    return (
        <tr className="[&>*]:px-2 [&>*]:py-5 border-t border-primary/10 cursor-context-menu select-none hover:bg-primary2">
            <td className=" lg:hidden">
                <span className="text-secondaryTextColor lg:textBody-s3 textBody-xs3">{`#${id ?? '0'}`}</span>
                <span className="lg:textBody-s2 textBody-xs2">{fullname ?? 'null'}</span>
                <span className="text-primary hover:text-primary/90 line-clamp-1">{email ?? 'null'}</span>
                <span className="lg:textBody-xs3 textBody-2xs3">{dayjs(created_at).format("D MMMM, YYYY")} <span className="max-lg:hidden">({dayjs(created_at).fromNow()})</span></span>
            </td>
            <td className=" max-lg:hidden">
                <span className="text-secondaryTextColor lg:textBody-s3 textBody-xs3">{`#${id ?? '0'}`}</span>
            </td>
            <td className=" max-lg:hidden">
                <td className="">
                    <span className="lg:textBody-s2 textBody-xs2">{fullname ?? 'null'}</span>
                </td>
            </td>
            <td className=" max-lg:hidden">
                <div className="flex flex-col gap-1 lg:textBody-s3 textBody-xs3">
                    <span className="text-primary hover:text-primary/90 line-clamp-1">{email ?? 'null'}</span>
                </div>
            </td>
            <td className="">
                <span className="lg:textBody-s3 textBody-xs3">{message ?? 'null'}</span>
            </td>
            <td className=" max-lg:hidden ltr:text-right rtl:text-left">
                <span className="lg:textBody-xs3 textBody-2xs3">{dayjs(created_at).format("D MMMM, YYYY")} <span className="max-lg:hidden">({dayjs(created_at).fromNow()})</span></span>
            </td>
        </tr>
    )
}
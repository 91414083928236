import { Hide1 } from "../../../icons"
import ModalLayout from "../../../layouts/ModalLayout"

export default function FilterModal({
    onClose = () => { },
    onSave = () => { },
    clearAllFilters = () => { },
    children
}) {


    return (
        <ModalLayout
            close={onClose}
        >
            <div className={`bg-white textBody-s3 w-full lg:rounded-lg gap-1 justify-between flex flex-col overflow-hidden max-lg:h-dvh`}>
                <div className="flex items-center justify-between p-4">
                    <h3 className="textBody-m1">Filters</h3>
                    <button
                        onClick={onClose}
                        className="rounded-lg p-1 hover:bg-primary/5">
                        <Hide1 className="lg:w-5 lg:h-5 w-4 h-4" />
                    </button>
                </div>

                <div className="py-4 px-4 flex flex-col gap-3 border-y border-primaryBorder lg:max-h-[60vh] max-lg:h-full overflow-y-auto">
                    {children}
                </div>

                <div className="pt-2 pb-3 px-4 flex items-center gap-2 justify-between">
                    <button
                        onClick={clearAllFilters}
                        className="outline-none hover:underline textBody-s2 text-primary/70">
                        Clear all filters
                    </button>
                    <div className="flex items-center gap-2">
                        <button
                            onClick={onClose}
                            className={`bg-transparent border border-primaryBorder text-secondaryTextColor hover:bg-secondaryTextColor/5 active:scale-[.99] rounded-lg py-2 px-6 transition-all duration-100 ease-in flex items-center justify-center gap-2 textBody-m2`}>
                            Cancel
                        </button>
                        <button
                            onClick={() => {
                                onSave()
                                onClose()
                            }}
                            className={`bg-primary border border-primary text-white hover:bg-primary/80 hover:border-primary/80 active:scale-[.99] rounded-lg py-2 px-6 transition-all duration-100 ease-in flex items-center justify-center gap-2 textBody-m1`}>
                            Apply
                        </button>
                    </div>
                </div>
            </div>
        </ModalLayout>
    )
}
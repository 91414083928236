import { useEffect, useState } from "react";
import FilterModal from "../../../Shared/FilterModal";



export default function FilterPopup({ params, setParams, toggleModal }) {


    const [sort_by, setSort_by] = useState("")


    useEffect(() => {
        setSort_by(params?.sort_by ?? "")
    }, [params])


    // clear all filter by one click
    const clearAllFilters = () => {
        setParams({})
        toggleModal()
    }


    // rearrange selected data and research
    const handleFilterData = () => {
        const data = {
            sort_by
        };

        // return non-null keys only
        const body = Object.fromEntries(
            Object.entries(data).filter(([_, value]) => value !== '')
        );

        setParams(body)
    }


    return (
        <FilterModal
            onClose={toggleModal}
            onSave={handleFilterData}
            clearAllFilters={clearAllFilters}
        >
            <div className="my-4 flex flex-col gap-4">
                <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-4">
                    {/* <label className="flex flex-col gap-2">
                        <span className="textBody-s1">Sport</span>
                        <select
                            value={sport}
                            onChange={(e) => setSport(e.target.value)}
                            className="p-3 rounded-lg bg-white border border-primaryBorder/60 hover:border-primaryBorder/90 transition-colors duration-300 outline-primary/80">
                            <option value=""></option>
                            {sportsLoading
                                ? <option value="" disabled>Loading...</option>
                                : <>
                                    {sportsData?.data?.data?.map((sport) => (
                                        <option key={sport.id} value={sport.id}>{sport.name}</option>
                                    ))}
                                </>
                            }
                        </select>
                    </label> */}

                    {/* ... */}
                </div>

                <label className="flex flex-col gap-2">
                    <span className="textBody-s1">Sort by</span>
                    <select
                        value={sort_by}
                        onChange={(e) => setSort_by(e.target.value)}
                        className="p-3 rounded-lg bg-white border border-primaryBorder/60 hover:border-primaryBorder/90 transition-colors duration-300 outline-primary/80">
                        <option value=""></option>
                        <option value="desc">Newest First</option>
                        <option value="asc">Oldest First</option>
                    </select>
                </label>
            </div>
        </FilterModal>
    )
}
import { fetchData, useSWRFetch } from "../hooks/useFetch";


/**
 * All api requests in one place
 */

export const GetDashboardStatistics = (params) => useSWRFetch(`api/admin/statistics`, 'GET', params);
export const GetAllPublicSports = (params) => useSWRFetch(`api/GetAllPublicSports`, 'GET', params);
export const GetAllPublicSubscriptions = (params) => useSWRFetch(`api/GetAllPublicSubscriptions`, 'GET', params);
export const GetAllClients = (params) => useSWRFetch(`api/admin/clients`, 'GET', params);
export const GetAllClubs = (params) => useSWRFetch(`api/admin/clubs`, 'GET', params);
export const GetAllTickets = (params) => useSWRFetch(`api/admin/tickets`, 'GET', params);
export const GetAllCountries = (params) => useSWRFetch(`api/admin/GetAllCountries`, 'GET', params);
export const GetAllCitiesByCountryId = (params) => useSWRFetch(`api/admin/GetAllCitiesByCountryId`, 'GET', params, {}, true);
export const GetAllUsers = (params) => useSWRFetch(`api/admin/users`, 'GET', params);
export const GetAllCurrencies = (params) => useSWRFetch(`api/admin/GetAllCurrencies`, 'GET', params);
export const GetAllAffiliaters = (params) => useSWRFetch(`api/admin/GetAllAffiliaters`, 'GET', params);


export const CreateNewCountry = (data, params) => fetchData(`api/admin/CreateNewCountry`, 'POST', params, data);
export const EditCountry = (data, params) => fetchData(`api/admin/EditCountry`, 'PUT', params, data);
export const CreateNewCity = (data, params) => fetchData(`api/admin/CreateNewCity`, 'POST', params, data);
export const EditCity = (data, params) => fetchData(`api/admin/EditCity`, 'PUT', params, data);
export const CreateNewCurrency = (data, params) => fetchData(`api/admin/CreateNewCurrency`, 'POST', params, data);
export const EditCurrency = (data, params) => fetchData(`api/admin/EditCurrency`, 'PUT', params, data);
export const ToggleConfirmationById = (data, params) => fetchData(`api/admin/ToggleConfirmationById`, 'PUT', params, data);
import { NoResults } from "../../icons";

export default function NotResultsFound({
    marginClassNames = "my-4",
    title = "Title",
    desc = "Description",
    desc2 = "",
}) {
    return (
        <div className={`${marginClassNames} rounded-lg lg:h-80 h-56 border border-primary/15`}>
            <div className="w-full h-full flex flex-col items-center justify-center gap-1 text-center p-4 text-secondaryTextColor select-none">
                <NoResults className="w-12 h-w-12 mb-3" />
                <h2 className="textBody-m1">{title}</h2>
                <p className="textBody-s3">{desc}</p>
                {desc2 && <p className="textBody-s3">{desc2}</p>}
            </div>
        </div>
    )
}
import { isNull } from "../../../../Helpers/utils"
import NotResultsFound from "../../../NotResultsFound"
import ClientCard1 from "./ClientCard1"


export default function ClientsList1({ cities, select }) {

    return isNull(cities)
        ? <NotResultsFound title={"No results"} desc={"No cities found on this page"} desc2={"try adding new cities"} />
        : (
            <table className="mt-8 w-full">
                <thead>
                    <tr className="[&>*]:px-2 [&>*]:py-2 select-none">
                        <th className="ltr:text-left rtl:text-right lg:textBody-s1 textBody-xs1">Id</th>
                        <th className="ltr:text-left rtl:text-right lg:textBody-s1 textBody-xs1">en_name</th>
                        <th className="ltr:text-left rtl:text-right lg:textBody-s1 textBody-xs1">fr_name</th>
                        <th className="ltr:text-left rtl:text-right lg:textBody-s1 textBody-xs1">ar_name</th>
                        <th className="ltr:text-left rtl:text-right lg:textBody-s1 textBody-xs1">isVisible</th>
                        <th className="ltr:text-right rtl:text-left lg:textBody-s1 textBody-xs1">Clubs</th>
                    </tr>
                </thead>
                <tbody>
                    {cities.length > 0 &&
                        cities.map((client, index) => {
                            return (
                                <ClientCard1
                                    key={`city_card_1_${client.id}`}
                                    data={client}
                                    select={select}
                                />
                            )
                        })}
                </tbody>
            </table>
        )
}
import { useAuth } from "../../hooks/useAuth"
import { useEffect, useState } from "react"
import { useAlerts } from "../../hooks/useAlerts"
import useCookies from "../../hooks/useCookies"
import { Spinner } from "../../icons"


export default function Login() {

    const { login, isLoading } = useAuth()

    const { getCookie } = useCookies()

    const lastLoggingEmail = getCookie("_eolla");

    const { setAlert, renderAlerts } = useAlerts()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [errors, setErrors] = useState({})


    useEffect(() => {
        setEmail(lastLoggingEmail ? lastLoggingEmail : "")
    }, [lastLoggingEmail])

    // handle form submit
    const sendRequest = async (e) => {
        e.preventDefault();

        login({ setErrors, setAlert, email, password })
    }

    return (
        <div className="w-full min-h-screen bg-white lg:px-2 lg:py-2 py-4 px-2">
            <form onSubmit={sendRequest} className="w-fit flex flex-col gap-3">
                {renderAlerts()}
                <input
                    type="email"
                    placeholder="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="py-1 px-3 outline-primary border rounded-lg"
                />

                <input
                    type="password"
                    placeholder="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="py-1 px-3 outline-primary border rounded-lg"
                />

                {errors.email && <p className="text-red-500 textBody-xs3">{errors.email}</p>}
                {errors.password && <p className="text-red-500 textBody-xs3">{errors.password}</p>}

                <button disabled={isLoading} type="submit" className="py-1 px-3 outline-primary border rounded-lg flex justify-center items-center">
                    {!isLoading ? 'submit' : <Spinner className="w-5 h-5 animate-spin" />}
                </button>
            </form>
        </div>
    )
}
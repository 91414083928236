import { useEffect, useState } from "react";
import { isNull } from "../../../../Helpers/utils";
import FormModal from "../../../../components/Shared/FormModal"
import { EditCity } from "../../../../lib/api";



export default function Edit({ country, city, closeModel, refresh, setNotif }) {

    const [cityId, setCityId] = useState("")
    const [en_name, setEn_Name] = useState("")
    const [fr_name, setFr_Name] = useState("")
    const [ar_name, setAr_Name] = useState("")
    const [status, setStatus] = useState("0")


    useEffect(() => {
        setCityId(city?.id)
        setEn_Name(city?.en_name)
        setFr_Name(city?.fr_name)
        setAr_Name(city?.ar_name)
        setStatus(city?.is_visible)
    }, [city])

    const [errors, setErrors] = useState({})
    const [inProgress, setInProgress] = useState(false)


    // handle click 'saveNew'
    const saveNew = async () => {

        const data = {
            country: country?.id,
            city_id: cityId,
            en_name,
            fr_name,
            ar_name,
            status: `${status}`,
        };

        setErrors({})
        setInProgress(true)

        const { is_ok, message, type, errors } = await EditCity(data);

        setInProgress(false)

        if (type === "validation") {
            setErrors(errors ?? {});
        } else {
            setNotif(message ?? (is_ok ? 'Success!' : 'Something was wrong!'), is_ok ? 1 : 0);
        }

        if (is_ok) {
            refresh()
            closeModel();
        }
    }

    return (
        <FormModal
            close={closeModel}
            title={"Edit city"}
            save={saveNew}
            isDisabled={inProgress}
            isLoading={inProgress}
        >
            <div className="w-full grid grid-cols-1 gap-6">
                <div className="w-full grid grid-cols-3 lg:gap-4 gap-2">
                    <label className="flex flex-col gap-2">
                        <span className="textBody-xs2">En name</span>
                        <div className={`flex justify-between transition-colors duration-300 rounded-lg overflow-hidden ${!isNull(errors.en_name) ? 'border border-red-600 outline-none' : 'border border-primaryBorder/60 hover:border-primaryBorder/90'}`}>
                            <input
                                type="text"
                                className={`w-full p-3 outline-none`}
                                onChange={(e) => setEn_Name(e.target.value)}
                                value={en_name}
                            />
                        </div>
                        {!isNull(errors.en_name) && <p className="textBody-s2 text-red-600">{errors.en_name}</p>}
                    </label>

                    <label className="flex flex-col gap-2">
                        <span className="textBody-xs2">Fr name</span>
                        <div className={`flex justify-between transition-colors duration-300 rounded-lg overflow-hidden ${!isNull(errors.fr_name) ? 'border border-red-600 outline-none' : 'border border-primaryBorder/60 hover:border-primaryBorder/90'}`}>
                            <input
                                type="text"
                                className={`w-full p-3 outline-none`}
                                onChange={(e) => setFr_Name(e.target.value)}
                                value={fr_name}
                            />
                        </div>
                        {!isNull(errors.fr_name) && <p className="textBody-s2 text-red-600">{errors.fr_name}</p>}
                    </label>

                    <label className="flex flex-col gap-2">
                        <span className="textBody-xs2">Ar name</span>
                        <div className={`flex justify-between transition-colors duration-300 rounded-lg overflow-hidden ${!isNull(errors.ar_name) ? 'border border-red-600 outline-none' : 'border border-primaryBorder/60 hover:border-primaryBorder/90'}`}>
                            <input
                                type="text"
                                className={`w-full p-3 outline-none`}
                                onChange={(e) => setAr_Name(e.target.value)}
                                value={ar_name}
                            />
                        </div>
                        {!isNull(errors.ar_name) && <p className="textBody-s2 text-red-600">{errors.ar_name}</p>}
                    </label>
                </div>

                <div className="w-full grid grid-cols-3 lg:gap-4 gap-2">
                    <label className="flex flex-col gap-2">
                        <span className="textBody-xs2">Country</span>
                        <div className={`flex justify-between transition-colors duration-300 rounded-lg overflow-hidden ${!isNull(errors.country) ? 'border border-red-600 outline-none' : 'border border-primaryBorder/60 hover:border-primaryBorder/90'}`}>
                            <select
                                className={`w-full p-3 outline-none appearance-none`}
                                disabled
                            >
                                <option value={country.id}>{country.en_name}</option>
                            </select>
                        </div>
                        {!isNull(errors.country) && <p className="textBody-s2 text-red-600">{errors.country}</p>}
                    </label>

                    <label className="flex flex-col gap-2">
                        <span className="textBody-xs2">Status</span>
                        <div className={`flex justify-between transition-colors duration-300 rounded-lg overflow-hidden ${!isNull(errors.status) ? 'border border-red-600 outline-none' : 'border border-primaryBorder/60 hover:border-primaryBorder/90'}`}>
                            <select
                                value={status}
                                onChange={(e) => setStatus(e.target.value)}
                                className={`w-full p-3 outline-none appearance-none`}
                            >
                                <option value="1">active</option>
                                <option value="0">inactive</option>
                            </select>
                        </div>
                        {!isNull(errors.status) && <p className="textBody-s2 text-red-600">{errors.status}</p>}
                    </label>
                </div>
            </div>
        </FormModal>
    )
}
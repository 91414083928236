import { useState } from "react";
import Filters from "../../components/Pages/Countries/Filters";
import Heading from "../../components/Pages/Countries/Heading";
import ClientsList1 from "../../components/Pages/Countries/ClientsList1";
import { debounce, isNull, n } from "../../Helpers/utils";
import GlobalSuspense from "../../components/SuspensePages/GlobalSuspense";
import Pagination from "../../components/Pagination";
import { GetAllCountries } from "../../lib/api";
import Create from "../../components/Pages/Countries/Create";
import Edit from "../../components/Pages/Countries/Edit";
import { useAlerts } from "../../hooks/useAlerts";


const Countries = () => {

    const { setNotif, renderAlerts } = useAlerts()

    const [pageNumber, setPageNumber] = useState(1)
    const [searchQuery, setSearchQuery] = useState("")
    const [params, setParams] = useState({})

    const paramsData = { page: pageNumber, search_query: searchQuery, ...params };

    const { data, loading, refresh } = GetAllCountries(paramsData);
    const countries = data?.data?.data ?? [];


    // search filter
    const handleSearchFilter = debounce(txt => {
        setSearchQuery(txt)
    }, 500);


    // state to store 'create' modal status
    const [showCreateModel, setShowCreateModel] = useState(false)
    const toggleCreateModel = () => setShowCreateModel(!showCreateModel);


    // State to store id of selected country
    const [selectedCountryId, setSelectedCountryId] = useState("")


    // Get Obj of selected country
    const selectedCountryData = !isNull(selectedCountryId) ? countries?.find(c => c.id === n(selectedCountryId)) : {};


    return (
        <div className="w-full xl:p-8 lg:p-6 sm:p-4 p-2">
            {renderAlerts()}

            <Heading
                totalRows={data?.data?.total ?? 0}
                paramsData={paramsData}
                toggleCreateModel={toggleCreateModel}
            />

            <Filters
                params={params}
                setParams={setParams}
                handleSearchFilter={handleSearchFilter}
            />


            {/* Show countries list */}
            <div className="w-full overflow-x-auto">
                {loading
                    ? <GlobalSuspense />
                    : <ClientsList1
                        countries={countries}
                        select={setSelectedCountryId}
                    />
                }
            </div>


            {/* Create */}
            {showCreateModel && (
                <Create
                    closeModel={toggleCreateModel}
                    refresh={refresh}
                    setNotif={setNotif}
                />
            )}


            {/* Edit */}
            {!isNull(selectedCountryData) && (
                <Edit
                    country={selectedCountryData}
                    closeModel={() => setSelectedCountryId("")}
                    refresh={refresh}
                    setNotif={setNotif}
                />
            )}

            <Pagination
                currentPageNumber={data?.data?.current_page}
                lastPageNumber={data?.data?.last_page}
                onChange={(n) => setPageNumber(n)}
                disabled={loading}
            />
        </div>
    )
}
export default Countries;
import { useState } from "react";
import { Hide1, Search } from "../../../../icons";

export default function SearchBox({ value, handleOnChange }) {

    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => setIsFocused(false);

    const labelClass = `border px-4 rounded-full bg-white flex items-center gap-2 w-full lg:max-w-[70%] max-w-[90%] transition-all duration-200 ease-in
        ${isFocused ? 'border-primary' : 'border-primaryBorder'}`;

    return (
        <label className={labelClass}>
            <Search className="w-4 h-4"/>
            <input
                placeholder={"Search by id, name, email, phone"}
                type="text"
                className="outline-none bg-transparent w-full py-2 lg:px-4 px-2 textBody-s3"
                onFocus={handleFocus}
                onBlur={handleBlur}
                value={value}
                onChange={(e) => handleOnChange(e.target.value)}
            />
            {value ? <span onClick={() => handleOnChange("")}><Hide1 className="w-4 h-4 hover:scale-110" /></span> : null}
        </label>
    )
}


export default function ClientCard1({ data = {}, select }) {

    const { id, en_name, fr_name, ar_name, code, symbol, is_visible } = data;

    return (
        <tr
            onClick={() => select(id)}
            className="[&>*]:px-2 [&>*]:py-5 border-t border-primary/10 select-none hover:bg-primary2 cursor-pointer">
            <td>
                <span className="text-secondaryTextColor lg:textBody-s3 textBody-xs3">{`#${id}`}</span>
            </td>
            <td>
                <span className="lg:textBody-s2 textBody-xs2">{en_name}</span>
            </td>
            <td>
                <span className="lg:textBody-s2 textBody-xs2">{fr_name}</span>
            </td>
            <td>
                <span className="lg:textBody-s2 textBody-xs2">{ar_name}</span>
            </td>
            <td className="">
                <span className="text-primary hover:text-primary/90 line-clamp-1">{code ?? 'null'}</span>
            </td>
            <td className="">
                <span className="text-primary hover:text-primary/90 line-clamp-1">{symbol ?? 'null'}</span>
            </td>
            <td className="ltr:text-right rtl:text-left">
            <span className={`lg:textBody-xs2 textBody-2xs2 ${is_visible ? 'text-green-600' : 'text-red-600'}`}>{is_visible ? 'Yes' : 'No'}</span>
            </td>
        </tr>
    )
}
import { useState } from "react";
import Filters from "../../components/Pages/Currencies/Filters";
import Heading from "../../components/Pages/Currencies/Heading";
import ClientsList1 from "../../components/Pages/Currencies/ClientsList1";
import { debounce, isNull, n } from "../../Helpers/utils";
import GlobalSuspense from "../../components/SuspensePages/GlobalSuspense";
import Pagination from "../../components/Pagination";
import { GetAllCurrencies } from "../../lib/api";
import Create from "../../components/Pages/Currencies/Create";
import Edit from "../../components/Pages/Currencies/Edit";
import { useAlerts } from "../../hooks/useAlerts";


const Currencies = () => {

    const { setNotif, renderAlerts } = useAlerts()

    const [pageNumber, setPageNumber] = useState(1)
    const [searchQuery, setSearchQuery] = useState("")
    const [params, setParams] = useState({})

    const paramsData = { page: pageNumber, search_query: searchQuery, ...params };

    const { data, loading, refresh } = GetAllCurrencies(paramsData);
    const currencies = data?.data?.data ?? [];


    // search filter
    const handleSearchFilter = debounce(txt => {
        setSearchQuery(txt)
    }, 500);


    // state to store 'create' modal status
    const [showCreateModel, setShowCreateModel] = useState(false)
    const toggleCreateModel = () => setShowCreateModel(!showCreateModel);


    // State to store id of selected Currency
    const [selectedCurrencyId, setSelectedCurrencyId] = useState("")


    // Get Obj of selected Currency
    const selectedCurrencyData = !isNull(selectedCurrencyId) ? currencies?.find(c => c.id === n(selectedCurrencyId)) : {};


    return (
        <div className="w-full xl:p-8 lg:p-6 sm:p-4 p-2">
            {renderAlerts()}

            <Heading
                totalRows={data?.data?.total ?? 0}
                paramsData={paramsData}
                toggleCreateModel={toggleCreateModel}
            />

            <Filters
                params={params}
                setParams={setParams}
                handleSearchFilter={handleSearchFilter}
            />


            {/* Show Currencies list */}
            <div className="w-full overflow-x-auto">
                {loading
                    ? <GlobalSuspense />
                    : <ClientsList1
                        currencies={currencies}
                        select={setSelectedCurrencyId}
                    />
                }
            </div>


            {/* Create */}
            {showCreateModel && (
                <Create
                    closeModel={toggleCreateModel}
                    refresh={refresh}
                    setNotif={setNotif}
                />
            )}


            {/* Edit */}
            {!isNull(selectedCurrencyData) && (
                <Edit
                    currency={selectedCurrencyData}
                    closeModel={() => setSelectedCurrencyId("")}
                    refresh={refresh}
                    setNotif={setNotif}
                />
            )}

            <Pagination
                currentPageNumber={data?.data?.current_page}
                lastPageNumber={data?.data?.last_page}
                onChange={(n) => setPageNumber(n)}
                disabled={loading}
            />
        </div>
    )
}
export default Currencies;
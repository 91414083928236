import { isNull } from "../../../../Helpers/utils"
import NotResultsFound from "../../../NotResultsFound"
import ClientCard1 from "./ClientCard1"


export default function ClientsList1({ countries, select }) {

    return isNull(countries)
        ? <NotResultsFound title={"No results"} desc={"No countries found on this page"} desc2={"try adding new countries"} />
        : (
            <table className="mt-8 w-full">
                <thead>
                    <tr className="[&>*]:px-2 [&>*]:py-2 select-none">
                        <th className="ltr:text-left rtl:text-right lg:textBody-s1 textBody-xs1">Id</th>
                        <th className="ltr:text-left rtl:text-right lg:textBody-s1 textBody-xs1">en_name</th>
                        <th className="ltr:text-left rtl:text-right lg:textBody-s1 textBody-xs1">fr_name</th>
                        <th className="ltr:text-left rtl:text-right lg:textBody-s1 textBody-xs1">ar_name</th>
                        <th className="ltr:text-left rtl:text-right lg:textBody-s1 textBody-xs1">slug</th>
                        <th className="ltr:text-left rtl:text-right lg:textBody-s1 textBody-xs1">phoneCode</th>
                        <th className="ltr:text-left rtl:text-right lg:textBody-s1 textBody-xs1">flag</th>
                        <th className="ltr:text-left rtl:text-right lg:textBody-s1 textBody-xs1">isVisible</th>
                        <th className="ltr:text-right rtl:text-left lg:textBody-s1 textBody-xs1">Cities</th>
                    </tr>
                </thead>
                <tbody>
                    {countries.length > 0 &&
                        countries.map((client, index) => {
                            return (
                                <ClientCard1
                                    key={`client_card_1_${client.id}`}
                                    data={client}
                                    select={select}
                                />
                            )
                        })}
                </tbody>
            </table>
        )
}
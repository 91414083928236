import { useState } from "react";
import Avatar from "../Shared/Avatar";
import ModalLayout from "../../layouts/ModalLayout";
import AvatarLoader from "../Shared/Avatar/AvatarLoader";
import { Spinner } from "../../icons";
import { renderImage } from "../../Helpers/utils";


export default function AvatarDropDown({ authUser, logout, inProgress }) {

    const [isOpen, setIsOpen] = useState(false)
    const toggleDropDown = () => setIsOpen(!isOpen)


    return authUser.isLoading ? <AvatarLoader size={'lg:w-12 lg:h-12 w-8 h-8'} /> : (
        <div className="relative">
            <Avatar
                src={renderImage(authUser?.data?.avatar)}
                size={'lg:w-12 lg:h-12 w-8 h-8'}
                alt={`${authUser?.data?.firstname ?? '#'} ${authUser?.data?.lastname ?? '#'}`}
                onClick={toggleDropDown}
                style={{ cursor: 'pointer' }}
            />

            {isOpen && (
                <ModalLayout
                    wallBackground={`bg-black/50`}
                    className={`absolute ltr:right-0 rtl:left-0 z-[1012]`}
                    close={toggleDropDown}
                >
                    <div className="shadow-lg bg-white animation-01 animation-02 w-[300px] h-full rounded-lg overflow-hidden pb-2 flex flex-col textBody-s3">
                        <h3 className="px-4 line-clamp-1 textBody-s1 border-b border-secondaryTextColor/20 pt-4 pb-4 mb-2">
                            {`${authUser?.data?.firstname ?? '#'} ${authUser?.data?.lastname ?? '#'}`}
                        </h3>

                        <a href="https://support.aratly.com/" target="_blank" rel="noreferrer" className="flex flex-col">
                            <button
                                className="text-start whitespace-nowrap py-2 px-4 hover:bg-primary/5 transition-colors duration-300">
                                Help Center
                            </button>
                        </a>

                        <button
                            disabled={inProgress}
                            onClick={() => {
                                logout()
                            }}
                            className={`text-start whitespace-nowrap py-2 px-4 transition-colors duration-300 flex items-center justify-between ${inProgress ? 'bg-primary/5' : 'hover:bg-primary/5'}`}>
                            <span>Logout</span>
                            {inProgress ? <Spinner className="w-4 h-4 animate-spin" /> : null}
                        </button>
                    </div>
                </ModalLayout>
            )}
        </div>
    )
}
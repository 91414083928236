import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from './components/ScrollToTop';
import PrimaryLayout from './layouts/PrimaryLayout';
import Dashboard from './pages/Dashboard';
import NotFound from './pages/NotFound';
import ClientsList from './pages/Clients/List';
import UsersList from './pages/Users/List';
import ClubsList from './pages/Clubs/List';
import Login from './pages/Auth/Login';
import AuthLayout from './layouts/AuthLayout';
import Tickets from './pages/Tickets';
import Countries from './pages/Countries/List';
import Cities from './pages/Cities/List';
import Currencies from './pages/Currencies/List';
import Affiliaters from './pages/Affiliaters/List';


function App() {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Routes>
                <Route path="/auth" element={<AuthLayout />}>
                    <Route path="login" element={<Login />} />
                </Route>

                <Route path="/" element={<PrimaryLayout />}>
                    <Route index element={<Dashboard />} />
                    <Route path="clients" element={<ClientsList />} />
                    <Route path="users" element={<UsersList />} />
                    <Route path="clubs" element={<ClubsList />} />
                    <Route path="tickets" element={<Tickets />} />
                    <Route path="activities" element={<>activities</>} />
                    <Route path="countries" element={<Countries />} />
                    <Route path="cities/:id" element={<Cities />} />
                    <Route path="currencies" element={<Currencies />} />
                    <Route path="affiliaters" element={<Affiliaters />} />
                </Route>

                <Route path='*' element={<NotFound />}></Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;

import { isNull, n, renderImage } from "../../../../Helpers/utils";
import { Spinner } from "../../../../icons";
import dayjs from "../../../../lib/dayjs";
import Avatar from "../../../Shared/Avatar";


export default function ClientCard1({ data = {}, toggleConfirmation, inProgress, disableButtons }) {

    const { id, firstname, lastname, email, phone, avatar, isDeleted, isConfirmed, created_at } = data;

    const isLoading = !isNull(inProgress) && n(inProgress) === n(id);

    return (
        <tr className="[&>*]:px-2 [&>*]:py-5 border-t border-primary/10 cursor-context-menu select-none hover:bg-primary2">
            <td>
                <span className="text-secondaryTextColor lg:textBody-s3 textBody-xs3">{`#${id}`}</span>
            </td>
            <td>
                <div className="flex lg:items-center gap-2">
                    <div className="">
                        <Avatar
                            src={renderImage(avatar)}
                            alt={`${firstname} ${lastname}`}
                        />
                    </div>
                    <div className="flex flex-col">
                        <h3 className="lg:textBody-m3 textBody-s2">{`${firstname} ${lastname}`}</h3>
                    </div>
                </div>
            </td>
            <td>
                <div className="flex flex-col gap-1 lg:textBody-s3 textBody-xs3">
                    <span className="text-primary hover:text-primary/90 line-clamp-1">{email ?? 'null'}</span>
                    <span className="text-primary hover:text-primary/90 line-clamp-1">{phone ?? 'null'}</span>
                </div>
            </td>
            <td>
                <p className="text-[#4CAF50] lg:textBody-s2 textBody-xs2 whitespace-nowrap">
                    {isConfirmed
                        ? <span className="text-[#4CAF50]">Yes</span>
                        : <span className="text-[#af4c4c]">No</span>
                    }
                </p>
            </td>
            <td>
                <p className="text-[#4CAF50] lg:textBody-s2 textBody-xs2 whitespace-nowrap">
                    {isDeleted
                        ? <span className="text-[#4CAF50]">Yes</span>
                        : <span className="text-[#af4c4c]">No</span>
                    }
                </p>
            </td>
            <td className="whitespace-nowrap">
                <span className="lg:textBody-xs3 textBody-2xs3">{dayjs(created_at).format("D MMMM, YYYY")} <span>({dayjs(created_at).fromNow()})</span></span>
            </td>
            <td className="text-right whitespace-nowrap">
                <button
                    disabled={disableButtons || isLoading}
                    onClick={() => toggleConfirmation(id)}
                    className="bg-primary text-white textBody-xs3 rounded-lg px-3 py-2 outline-none">
                    {isLoading
                        ? <Spinner className="w-4 h-4 animate-spin" />
                        : isConfirmed ? 'inActive' : 'active'
                    }
                </button>
            </td>
        </tr>
    )
}
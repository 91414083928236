import { isNull } from "../../../../Helpers/utils"
import NotResultsFound from "../../../NotResultsFound"
import ClientCard1 from "./ClientCard1"


export default function ClientsList1({ tickets }) {

    return isNull(tickets)
        ? <NotResultsFound title={"No results"} desc={"No clients found on this page"} desc2={"try adding new clients"} />
        : (
            <table className="mt-8 w-full">
                <thead>
                    <tr className="[&>*]:px-2 [&>*]:py-2 select-none">
                        <th className="ltr:text-left rtl:text-right lg:textBody-s1 textBody-xs1 lg:hidden">Ticket</th>
                        <th className="ltr:text-left rtl:text-right lg:textBody-s1 textBody-xs1 max-lg:hidden">Id</th>
                        <th className="ltr:text-left rtl:text-right lg:textBody-s1 textBody-xs1 max-lg:hidden">Name</th>
                        <th className="ltr:text-left rtl:text-right lg:textBody-s1 textBody-xs1 max-lg:hidden">Email</th>
                        <th className="ltr:text-left rtl:text-right lg:textBody-s1 textBody-xs1">Message</th>
                        <th className="ltr:text-right rtl:text-left lg:textBody-s1 textBody-xs1 max-lg:hidden">Joined at</th>
                    </tr>
                </thead>
                <tbody>
                    {tickets.length > 0 &&
                        tickets.map((client, index) => {
                            return (
                                <ClientCard1
                                    key={`client_card_1_${client.id}`}
                                    data={client}
                                />
                            )
                        })}
                </tbody>
            </table>
        )
}